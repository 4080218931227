<template>
  <div id="myinfomation">
    <van-nav-bar title="个人资料" :border="false" @click-left="goLastIndex" left-arrow>
    </van-nav-bar>
    <div class="topBac">

    </div>
    <div class="registeredWrap">
      <van-cell-group :border="false">
        <van-cell center title="基本资料" is-link label="更换手机号、修改密码等" :to="{name: 'base_data'}" />
        <van-cell center title="服务信息" is-link label="设置服务区域、服务类型等" :to="{name: 'serve_data'}" />
        <van-cell title="服务承诺" is-link :to="{name: 'promise_data'}" />
        <van-cell is-link value="待完善">
          <template #title>
            <span class="custom-title">证书认证</span>
            <van-tag type="danger">推荐</van-tag>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  created() {},
  methods: {
    goLastIndex() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less">
#myinfomation {
  .topBac {
    width: 100%;
    height: 15rem;
    background: @themeColor;
  }
  .registeredWrap {
    box-sizing: border-box;
    width: 90%;
    padding: 2rem 0;
    border-radius: 10px;
    // height: 35rem;
    background: #fff;
    position: absolute;
    left: calc(50% - 45%);
    // top: 19rem;
    top: 11rem;

    .van-tabs--line {
      width: 100%;
      .van-tabs__content {
        margin: 2rem 0;
      }
    }
    .public {
      width: 88%;
      height: 4.6rem;
      padding: 1rem;
      position: relative;
      margin: 0 auto;
    }
    .custom-title {
      margin-right: 4px;
      vertical-align: middle;
    }

    .search-icon {
      font-size: 16px;
      line-height: inherit;
    }
  }
}
</style>